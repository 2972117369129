export default [
  {
    key: 'channelId',
    label: 'field.channel',
    thClass: 'w-50px',
  },
  {
    key: 'fightMatchNo',
    label: 'field.fight',
    thClass: 'w-50px',
  },
  {
    key: 'createdAt',
    label: 'field.date',
  },
  {
    key: 'createdAt',
    label: 'field.date',
  },
  {
    key: 'payout',
    label: 'field.odd',
  },
  {
    key: 'selectedBet',
    label: 'field.bet',
  },
  {
    key: 'matchResult',
    label: 'field.result',
  },
  {
    key: 'wL',
    label: 'field.winLoss',
  },
];