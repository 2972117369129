export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    sortable: false,
    sortField: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
    tdClass: 'limit-size',
  },
  {
    key: 'username',
    sortable: false,
    sortField: 'username',
    label: 'field.username',
  },
  {
    key: 'turnover',
    sortable: false,
    sortField: '',
    label: 'field.turnover',
    tdClass: 'text-right',
  },
  {
    key: 'netWl',
    sortable: false,
    sortField: '',
    label: 'field.netWinLoss',
    tdClass: 'text-right',
  },
  {
    key: 'commission',
    sortable: false,
    sortField: '',
    label: 'field.commission',
    tdClass: 'text-right',
  },
  {
    key: 'newWl',
    sortable: false,
    sortField: '',
    label: 'field.newWinLoss',
    tdClass: 'text-right',
  },
  {
    key: 'deposit',
    sortable: false,
    sortField: '',
    label: 'field.deposit',
    tdClass: 'text-right',
  },
  {
    key: 'withdrawal',
    sortable: false,
    sortField: '',
    label: 'field.withdraw',
    tdClass: 'text-right',
  },
  {
    key: 'balance',
    sortable: false,
    sortField: '',
    label: 'field.balance',
    tdClass: 'text-right text-brand',
  },
  {
    key: 'outstanding3',
    sortable: false,
    sortField: '',
    label: 'field.outstandingBaccarat1',
    tdClass: 'text-center',
  },
  {
    key: 'outstanding4',
    sortable: false,
    sortField: '',
    label: 'field.outstandingBaccarat2',
    tdClass: 'text-center',
  },
  {
    key: 'isVendor',
    label: 'field.playerType',
  },
  {
    key: 'status',
    sortable: false,
    sortField: '',
    label: 'field.status',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
